export interface UrlType {
  name: string;
  // eslint-disable-next-line
  params?: any;
}

export interface InvoiceType {
  id: string | number;
  category?: string;
  url: string | UrlType;
  slug: string;
  img: string;
  title: string;
  description: string;
  actionTxt?: string;
  views?: number;
  price?: string | number;
}

export const availableInvoiceTemplates: Array<InvoiceType> = [
  // {
  //   id: 1,
  //   title: "Classic",
  //   url: {
  //     name: "ClassicTemplate",
  //   },
  //   slug: `classic-invoice`,
  //   price: 0,
  //   img: "https://source.unsplash.com/random/722x400/?invoice",
  //   description: "Lorem Ipsum is simply Lorem Ipsum and Lorem Ipsum in Spanish",
  // },
  {
    id: 2,
    title: "Simple",
    url: {
      name: "SimpleTemplate",
    },
    slug: `simple-invoice`,
    price: 0,
    img: "https://source.unsplash.com/random/722x400/?invoice",
    description: "Simple Invoice template",
  },
];


import { defineComponent } from "vue";
import InvoicePreviewTile from "@/components/invoice/InvoicePreviewTile.vue";

import { availableInvoiceTemplates } from "@/config/templates";

export default defineComponent({
  data() {
    return {
      availableInvoiceTemplates,
    };
  },

  components: { InvoicePreviewTile },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "invoice-preview" }
const _hoisted_2 = { class: "text-gray-600 body-font" }
const _hoisted_3 = { class: "container px-5 py-24 mx-auto" }
const _hoisted_4 = { class: "flex flex-wrap -m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_invoice_preview_tile = _resolveComponent("invoice-preview-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableInvoiceTemplates, (tile) => {
            return (_openBlock(), _createBlock(_component_invoice_preview_tile, {
              key: tile.slug ?? tile.id,
              tile: tile
            }, null, 8, ["tile"]))
          }), 128))
        ])
      ])
    ])
  ]))
}